"use strict";
/*!
 *
 *  product.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecTable = exports.ProductSlider = void 0;
var ProductSlider = /** @class */ (function () {
    function ProductSlider() {
        var _this = this;
        this.$slider = $('.prod-Slider');
        this.setting = {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                        // variableWidth: true
                    }
                },
                {
                    breakpoint: 769,
                    settings: 'unslick'
                }
            ]
        };
        if (this.$slider.length) {
            this.$slider.each(function (i, elem) {
                _this.buildSlider(elem);
            });
        }
        ;
    }
    ProductSlider.prototype.buildSlider = function (elem) {
        var $inner = $(elem).children('.prod-Slider_Inner');
        var $controls = $(elem).children('.prod-Slider_Controls');
        var $counter = $controls.children('.prod-Slider_Counter');
        var max = $inner.children('.prod-Slider_Item').length;
        $counter.text('1 / ' + max);
        var option = $.extend({}, this.setting, {
            appendArrows: $controls
        });
        $inner.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            $counter.text((nextSlide + 1) + ' / ' + max);
        });
        $inner.slick(option);
    };
    return ProductSlider;
}());
exports.ProductSlider = ProductSlider;
;
function SpecTable() {
    var $table = $('h3').filter(function (i, elem) {
        return $(elem).text() === '製品説明' || $(elem).text() === 'Product Description';
    }).nextAll('table').filter(function (i, elem) {
        return i === 0 && !$(elem).is('.sw-Scroll');
    });
    $table.addClass('tbl-Spec');
}
exports.SpecTable = SpecTable;
