"use strict";
/*!
 *
 *  fancybox-defaults-setting.js
 *
 */
$.fancybox.defaults.animationDuration = 700;
$.fancybox.defaults.transitionDuration = 700;
$.fancybox.defaults.arrows = true;
$.fancybox.defaults.infobar = false;
$.fancybox.defaults.toolbar = true;
$.fancybox.defaults.buttons = ['close'];
$.fancybox.defaults.smallBtn = false;
$.fancybox.defaults.wheel = false;
$.fancybox.defaults.transitionEffect = "slide";
$.fancybox.defaults.idleTime = 999999;
$.fancybox.defaults.spinnerTpl = "\n\t<div class=\"sw-Loading\">\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t</div>\n";
