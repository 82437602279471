"use strict";
/*!
 *
 *  form.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.$question = $('input[name="当社製品使用の有無"]');
        this.$reason = $('input[name="当社を知った理由"]');
        if (this.$question.length) {
            this.$question.on('change', function (e) {
                if ($('input[name="当社製品使用の有無"]:checked').val() === 'ある') {
                    $('select[name="使用した製品"]').prop('disabled', false);
                }
                else {
                    $('select[name="使用した製品"]').prop('disabled', true).val('');
                }
                ;
            });
        }
        ;
        if (this.$reason.length) {
            this.$reason.on('change', function (e) {
                if ($('input[name="当社を知った理由"]:checked').val() === 'その他') {
                    $('input[name="当社を知った理由（その他）"]').prop('disabled', false);
                }
                else {
                    $('input[name="当社を知った理由（その他）"]').prop('disabled', true).val('');
                }
                ;
            });
        }
        ;
    }
    return default_1;
}());
exports.default = default_1;
