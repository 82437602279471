"use strict";
/*!
 *
 *  local.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.$local = $('#Local');
        this.currentPath = location.pathname.replace(/\.html/g, '');
        if (this.$local.length) {
            this.$label = this.$local.find('.st-Local_Label');
            this.$list = this.$local.find('.st-Local_List');
            this.setCurrent();
            this.setEvent();
        }
        ;
    }
    default_1.prototype.setCurrent = function () {
        var _this = this;
        this.$list.find('a').each(function (i, elem) {
            var href = $(elem).attr('href');
            if (href === _this.currentPath) {
                $(elem).attr('aria-current', 'page');
            }
            ;
            if (href.search('/contact/') !== -1 && _this.currentPath.search(href) !== -1) {
                $(elem).attr('aria-current', 'page');
            }
            ;
        });
    };
    default_1.prototype.setEvent = function () {
        var _this = this;
        this.$label.on('click', function () {
            if (_this.$list.is(':visible')) {
                _this.close();
            }
            else {
                _this.open();
            }
            ;
        });
        this.$list.find('a').on('click', function (e) {
            if (util.viewport === 'phone') {
                var label = $(e.currentTarget).text();
                _this.$label.text(label).addClass('-active');
                _this.close();
            }
            ;
        });
        $(window).on('click', function (e) {
            if (util.viewport === 'phone' && _this.$list.is(':visible') && !$(e.target).closest('.st-Local_Inner').length) {
                _this.close();
            }
            ;
        }).on('viewportChanged', function () {
            if (util.viewport !== 'phone') {
                _this.$list.removeAttr('style');
            }
            ;
        });
    };
    default_1.prototype.open = function () {
        this.$list.stop(true, false).slideDown(500, 'easeOutQuart');
    };
    default_1.prototype.close = function () {
        this.$list.stop(true, false).slideUp(500, 'easeOutQuart');
    };
    return default_1;
}());
exports.default = default_1;
