"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$menu = $('#Menu');
        this.$btn = $('#MenuBtn');
        this.top = 0;
        this.checkViewport();
        $(window).on('viewportChanged', function () {
            _this.checkViewport();
        });
        this.$btn.on('click', function (e) {
            e.preventDefault();
            if (_this.$menu.is('[aria-expanded="true"]')) {
                _this.$menu.attr('aria-expanded', 'false');
                $('html').css({
                    overflow: ''
                });
                document.removeEventListener('touchmove', _this.scrollControll);
            }
            else {
                _this.$menu.attr('aria-expanded', 'true');
                $('html').css({
                    overflow: 'hidden'
                });
                document.addEventListener('touchmove', _this.scrollControll, {
                    passive: false
                });
            }
            ;
        });
    }
    default_1.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.st-Menu_Body_Inner');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    default_1.prototype.checkViewport = function () {
        if (util.viewport !== 'phone') {
            this.$menu.attr('aria-expanded', 'true');
        }
        else {
            this.$menu.attr('aria-expanded', 'false');
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
;
