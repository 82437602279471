"use strict";
/*!
 *
 *  agree-cookie.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("jquery.cookie");
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.domain = location.hostname;
        // name: string = 'hugahuga000';
        this.name = 'ztj-cookie-policy';
        this.$cookie = $('#Cookie');
        this.$btn = $('#CookieAgreeBtn');
        if (!$.cookie(this.name)) {
            this.$cookie.addClass('-visible');
        }
        else {
            this.$cookie.remove();
        }
        ;
        this.$btn.on('click', function (e) {
            e.preventDefault();
            _this.$cookie.addClass('-hide');
            $.cookie(_this.name, 'agree', {
                expires: 365,
                domain: _this.domain,
                path: '/'
            });
            setTimeout(function () {
                _this.$cookie.remove();
            }, 700);
        });
    }
    return default_1;
}());
exports.default = default_1;
