"use strict";
/*!
 *
 *  hasher.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function () {
    var hash = util.hash;
    if (util.hash.search('--') !== -1) {
        hash = '#' + util.hash.split('--').slice(-1)[0];
    }
    ;
    if (hash.length > 0 && hash.search('/') === -1) {
        location.hash = util.hash + '_';
        $(function () {
            if ($(hash).length) {
                $(window).on('load', function () {
                    setTimeout(function () {
                        console.log(util.scrollGap);
                        var offset = $(hash).closest('.c-Tab').length ? $(hash).closest('.c-Tab').offset() : $(hash).offset();
                        var top = offset !== undefined ? offset.top : 0;
                        $(util.scroller).animate({
                            scrollTop: top - util.scrollGap
                        }, 5);
                        history.replaceState(null, '', util.hash);
                    }, 10);
                });
            }
            else {
                history.replaceState(null, '', util.hash);
            }
            ;
        });
    }
    ;
});
