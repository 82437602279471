"use strict";
/*!
 *
 *  tel-handler.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
;
var initial = {
    enable: 'sw-TelLink -enable',
    disable: 'sw-TelLink -disable'
};
var isPhone = function () {
    var ua = navigator.userAgent.toLowerCase();
    var sdev = /iphone;|(android|nokia|blackberry|bb10;).+mobile|android.+fennec|opera.+mobi|windows phone|symbianos/;
    return sdev.test(ua);
};
exports.default = (function (option) {
    var $tel = $('a[href^="tel:"]');
    var setting = $.extend(initial, option);
    if (isPhone()) {
        $tel.addClass(setting.enable);
    }
    else {
        $tel.addClass(setting.disable).on('click', function (e) {
            e.preventDefault();
        });
    }
    ;
});
