"use strict";
/*!
 *
 *  page2top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('#Page2Top');
        this.$footer = $('.st-Footer_Copyright');
        this.isStatic = false;
        this.isVisible = false;
        this.borderLine = 0;
        this.borderLine = this.getBorder();
        $(window).on('resize', function (e) {
            _this.borderLine = _this.getBorder();
        });
        $(window).on('scroll', function (e) {
            var top = $(e.currentTarget).scrollTop();
            var bottom = top + util.wh - (util.viewport !== 'phone' ? util.viewport !== 'tablet' ? 30 : 110 : 100);
            if (!_this.isVisible && top > 0) {
                _this.isVisible = true;
                _this.$elem.addClass('-visible');
            }
            ;
            if (_this.isVisible && top <= 0) {
                _this.isVisible = false;
                _this.$elem.removeClass('-visible');
            }
            ;
            if (!_this.isStatic && bottom >= _this.borderLine) {
                _this.isStatic = true;
                _this.$elem.addClass('-static');
            }
            ;
            if (_this.isStatic && bottom < _this.borderLine) {
                _this.isStatic = false;
                _this.$elem.removeClass('-static');
            }
        });
        $(window).on('load acod-toggle tab-changed', function () {
            _this.borderLine = _this.getBorder();
        });
    }
    default_1.prototype.getBorder = function () {
        return this.$footer.offset().top - (util.viewport !== 'phone' ? util.viewport !== 'tablet' ? 30 : 30 : 20);
    };
    return default_1;
}());
exports.default = default_1;
